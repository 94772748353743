<template>
  <section class="info-container">
    <div v-for="item in infoData" :key="item.id">
      <div v-if="item.link" @click="handleClick(item.link, item.header)" class="info-card clickable">
        <div>
          <inline-svg class="icon-color" :src="`${$svg.getSvgUrl(item.icon)}`" />
          <h3 class="info-header">{{ item.header }}</h3>
        </div>
        <p class="info-text">{{ item.description }}</p>
      </div>
      <div v-else class="info-card">
        <div>
          <inline-svg class="icon-color" :src="`${$svg.getSvgUrl(item.icon)}`" />
          <h3 class="info-header">{{ item.header }}</h3>
        </div>
        <p class="info-text">{{ item.description }}</p>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "InfoCards",
  props: {
    infoData: Array
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    handleClick(link, linkText) {
      this.$gtm.trackEvent({
        event: "navigation_links",
        navigationLink: linkText
      });
      if (this.$route.path !== link.to) this.$router.push(link);
    }
  }
};
</script>